import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { regexPhoneNumber } from 'helpers/helpers'
import { GET, POST } from 'helpers/api'

import Logo from 'assets/images/logo_icon.png'
import { SET_MENU_DATA } from 'store/actions'

function Header() {
	const { pos_id, waiter_id } = useParams()
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const reduxMenuData = useSelector(state => state.menuData)

	async function getChequeByTableId() {
		await POST(`/app-cheque-table`, {
			"pos_id": reduxMenuData?.pos?.id,
			"table_id": reduxMenuData?.table?.table_id,
		})
	}

	async function getMenu() {
		const response = await GET(`/openapi/menu/${pos_id}`, { 'waiter_id': waiter_id })
		if (response) dispatch(SET_MENU_DATA(response))
	}

	useEffect(() => {
		getMenu()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="pos-header" style={{ backgroundColor: reduxMenuData.online_menu_theme?.type === 'dark' ? '#133852' : '#f4f4f4' }}>
				<div className="pos-image">
					{reduxMenuData?.pos?.logo ?
						<img src={`https://backend.mison.uz/uploads/pos/${reduxMenuData.pos.logo}`} alt="" />
						:
						<img src={Logo} alt="" />
					}
				</div>
				<div>
					<h2 className="pos-title" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>{reduxMenuData?.pos?.name}</h2>
					<div className="pos-info">
						<div className="pos-info__address">
							<div className="pos-info__block" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
								<i className="uil uil-location-point mr-10"></i>
								<span className="max-lines-3">Адрес: {reduxMenuData?.pos?.address}</span>
							</div>
						</div>
						<div className="pos-info__block" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
							<i className="uil uil-phone mr-10"></i>
							Телефон: {regexPhoneNumber(reduxMenuData?.pos?.phone)}
						</div>
						{reduxMenuData?.waiter_name &&
							<div className="pos-info__block" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
								<i className="uil uil-user mr-10"></i>
								<span className="max-lines-3">Офицант: {reduxMenuData?.waiter_name}</span>
							</div>
						}
					</div>
				</div>
				{reduxMenuData?.table?.table_id ?
					location.pathname !== '/cheque' ?
						<div className="pos-header-cheque" onClick={() => navigate('/cheque')}>
							<i className="uil uil-receipt"></i>
						</div>
						:
						<div className="pos-header-cheque" onClick={() => getChequeByTableId()}>
							<i className="uil uil-redo"></i>
						</div>
					:
					<div></div>
				}
			</div>
		</>
	)
}

export default Header
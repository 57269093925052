import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { regexPhoneNumber } from 'helpers/helpers'
import { POST } from 'helpers/api'

import Logo from 'assets/images/logo_icon.png'

function Header() {
	const location = useLocation()
	const navigate = useNavigate()
	const reduxMenuData = useSelector(state => state.menuData)

	async function getChequeByTableId() {
		await POST(`/app-cheque-table`, {
			"pos_id": reduxMenuData?.pos?.id,
			"table_id": reduxMenuData?.table?.table_id,
		})
	}

	return (
		<>
			<div className="pos-header" style={{ backgroundColor: reduxMenuData.online_menu_theme?.type === 'dark' ? '#133852' : '#f4f4f4' }}>
				<div className="pos-image">
					{reduxMenuData?.logo64 ?
						<img src={reduxMenuData?.logo64} alt="logo" />
						:
						<img src={Logo} alt="logo" />
					}
				</div>
				<div>
					<h2 className="pos-title"
						style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
						{reduxMenuData?.pos?.name}
					</h2>
					<div className="pos-info">
						<div className="pos-info__address">
							<div className="pos-info__block" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
								<i className="uil uil-location-point mr-10"></i>
								<span className="max-lines-3">{reduxMenuData?.pos?.address}</span>
							</div>
						</div>
						<div className="pos-info__block" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
							<i className="uil uil-phone mr-10"></i>
							{regexPhoneNumber(reduxMenuData?.pos?.phone)}
						</div>
						<div className="pos-info__description"></div>
					</div>
				</div>
				{reduxMenuData?.table?.table_id ?
					location.pathname !== '/cheque' ?
						<div className="pos-header-cheque" onClick={() => navigate('/cheque')}>
							<i className="uil uil-receipt"></i>
						</div>
						:
						<div className="pos-header-cheque" onClick={() => getChequeByTableId()}>
							<i className="uil uil-redo"></i>
						</div>
					:
					<div></div>
				}
			</div>
		</>
	)
}

export default Header
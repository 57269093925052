import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import Echo from 'laravel-echo';

import Header from './Header'
import Search from '../../pages/menu/Search'
import { SET_MENU_DATA } from 'store/actions';

function NoAuthLayout() {
	const dispatch = useDispatch()

	const reduxMenuData = useSelector(state => state.menuData)

	const [showStickyHeader, setShowStickyHeader] = useState(false)

	async function connectSocket() {
		var wsHost;
		var isProd;

		if (process.env.NODE_ENV === 'production') {
			wsHost = process.env.REACT_APP_PROD_WSHOST
			isProd = true
		} else {
			wsHost = process.env.REACT_APP_PROD_WSHOST
			isProd = false
		}

		var echo
		try {
			echo = new Echo({
				broadcaster: 'pusher',
				key: "mison_key",
				wsHost: wsHost,
				wsPort: 6001,
				wssPort: 6001,
				enabledTransports: ['ws', 'wss'],
				disableStats: true,
				encrypted: isProd ? true : false,
				useTLS: isProd ? true : false,
				forceTLS: isProd ? true : false,
			});
		} catch (error) {
			console.log(error)
		}

		echo.connector.pusher.connection.bind('connected', () => {
			console.log('connected public channel');
		});

		echo.connector.pusher.connection.bind('disconnected', () => {
			console.log('disconnected public channel');
		});

		echo.channel(`public.${reduxMenuData?.pos?.id}`)
			.listen(`PublicChannel`, (payload) => {
				console.log(payload)

				if (payload.type === 'qrMenuChequeResult') {
					if (reduxMenuData?.table?.table_id === payload.data.table_id) {
						dispatch(SET_MENU_DATA({ ...reduxMenuData, cheque: payload.data }))
						// changeCheque(payload.data)
					}
				}
			});
	}

	function handleScroll() {
		if (window.scrollY >= 190) {
			if (!showStickyHeader) {
				setShowStickyHeader(true)
			}
		} else {
			if (!showStickyHeader) {
				setShowStickyHeader(false)
			}
		}
	}

	useEffect(() => {
		connectSocket()
		window.removeEventListener('scroll', handleScroll);
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => window.removeEventListener('scroll', handleScroll);
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className={"sticky " + (showStickyHeader ? 'opacity-1' : 'opacity-0')}
				style={{ backgroundColor: reduxMenuData.online_menu_theme?.body_bg_color }}>
				<Search />
			</div>
			<div className="pos-content wrapper" style={{ backgroundColor: reduxMenuData.online_menu_theme?.body_bg_color }}>
				<Header />
				<Outlet />
			</div>
		</>
	)
}

export default NoAuthLayout
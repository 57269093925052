const cart = function (state = {}, action) {
	switch (action.type) {
		case "SET_MENU_DATA":
			return state = action.payload;
		default:
			return state;
		/* falls through */
	}
};

export default cart
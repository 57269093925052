import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Skeleton from 'react-loading-skeleton'
import Search from './Search'

import { POST } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'

import 'react-loading-skeleton/dist/skeleton.css'

function Cheques({ loading }) {
	const [cheque, setCheque] = useState({})
	const reduxMenuData = useSelector(state => state.menuData)

	async function getChequeByTableId() {
		await POST(`/app-cheque-table`, {
			"pos_id": reduxMenuData?.pos?.id,
			"table_id": reduxMenuData?.table?.table_id,
		})
	}

	useEffect(() => {
		getChequeByTableId()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (reduxMenuData?.cheque) {
			setCheque(reduxMenuData?.cheque)
		}
	}, [reduxMenuData])

	return (
		<>
			<div className="menu-list cheque" style={{ backgroundColor: reduxMenuData.online_menu_theme?.body_bg_color }}>
				<Search />
				<div className="mt-3"></div>
				<div className="cheque_row" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
					<div>
						<b>Официант:</b>
					</div>
					<div>
						{!loading ?
							(cheque?.waiter_first_name ?? '') + ' ' + (cheque?.waiter_last_name ?? '')
							:
							<Skeleton width={150} height={26} />
						}
					</div>
				</div>
				<div className="cheque_row" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
					<div>
						<b>Зал:</b>
					</div>
					<div>
						{!loading ?
							cheque?.hall_name ?? ''
							:
							<Skeleton width={150} height={26} />
						}
					</div>
				</div>
				<div className="cheque_row" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
					<div>
						<b>Стол:</b>
					</div>
					<div>
						{!loading ?
							cheque?.table_name ?? ''
							:
							<Skeleton width={150} height={26} />
						}
					</div>
				</div>
				<table className="table" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
					<thead>
						<tr>
							<th style={{ 'width': '50%' }}>№ Название</th>
							<th style={{ 'width': '25%' }} className="text-end">Кол-во</th>
							<th style={{ 'width': '25%' }} className="text-end">Сумма</th>
						</tr>
					</thead>
					<tbody>
						{!loading ?
							cheque?.products?.map((item, index) => (
								<tr key={index} className={'status-' + item.status}>
									<td>{index + 1}. {item.name}</td>
									<td className="text-end">{item.quantity} x {formatMoney(item.sale_price)}</td>
									<td className="text-end">{formatMoney(item.total_price)}</td>
								</tr>
							))
							:
							[{}, {}, {}, {}, {}].map((item, index) => (
								<tr key={index}>
									<td><Skeleton width={150} height={26} /></td>
									<td className="text-center"><Skeleton width={50} height={26} /></td>
									<td className="text-end"><Skeleton width={150} height={26} /></td>
								</tr>
							))}
					</tbody>
				</table>
				<div className="cheque_row_border" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
					<div>
						<b>Сумма:</b>
					</div>
					<div>
						{!loading ?
							formatMoney(cheque?.total_amount)
							:
							<Skeleton width={150} height={26} />
						}
					</div>
				</div>
				<div className="cheque_row_border" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
					<div>
						<b>Обслуживание: {cheque?.service_percentage ?? ''} %</b>
					</div>
					<div>
						{!loading ?
							formatMoney(cheque?.total_amount_service - cheque?.total_amount)
							:
							<Skeleton width={150} height={26} />
						}
					</div>
				</div>
				<div className="cheque_row_border fz-20" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
					<div>
						<b>Итого к оплате:</b>
					</div>
					<div>
						{!loading ?
							<div className="cheque-text">{formatMoney(cheque?.total_amount_service)}</div>
							:
							<Skeleton width={150} height={26} />
						}
					</div>
				</div>
				<div className="cheque_number" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
					<div className="fz-20">
						Ваш номер заказа
					</div>
					<b>{cheque?.cheque_number}</b>
				</div>
			</div>
		</>
	)
}

export default Cheques
import axios from 'axios'
import { showLoader, hideLoader } from '../store/loader/actions';
import { store } from '../store/index'
import { toast } from 'react-toastify'
import { clearTemporaryStorage } from 'helpers/helpers'
//menu.backend.mison.uz/:pos_id
var baseURL = '';
if (process.env.NODE_ENV === 'production') {
	baseURL = 'https://backend.mison.uz/api'
} else {
	 baseURL = 'http://127.0.0.1:8000/api'
	// baseURL = 'https://backend.mison.uz/api'
}

const axiosClient = axios.create({
	baseURL: baseURL,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
		"Language": localStorage.getItem("I18N_LANGUAGE")
	},
});

const axiosFile = axios.create({
	baseURL: baseURL,
	withCredentials: true,
	headers: {
		'Authorization': 'Bearer ' + localStorage.getItem('token'),
		'Content-Type': 'multipart/form-data',
		'Accept': 'application/json',
		"withCredentials": true,
		"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
		"Language": localStorage.getItem("I18N_LANGUAGE")
	},
});

export async function PGET(URL, payload = {}, settings = {}) {
	var params = "";
	if (Object.entries(payload).length > 0) {
		params = getPath(payload, URL);
	}

	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
		)
			.catch(error => {
				httpStatusChecker(error)
			})
		store.dispatch(hideLoader());
		return data.data
	} else {
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
		)
		return data.data
	}
}

export async function GET(URL, payload = {}, settings = {}) {
	var params = "";
	if (Object.entries(payload).length > 0) {
		params = getPath(payload);
	}

	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
		)
			.catch(error => {
				httpStatusChecker(error)
				return error
			})
		store.dispatch(hideLoader());
		if (data?.response?.status >= 300) {
			return data
		}
		return data?.data
	} else {
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
		).catch(error => {
			httpStatusChecker(error)
			return error
		})
		return data.data
	}
}

export async function POST(URL, payload, settings = {}) {
	let jsonData = JSON.stringify(payload);
	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosClient.post(
			URL, jsonData,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
		)
			.catch(error => {
				httpStatusChecker(error)
			})
		store.dispatch(hideLoader());
		if (data && data.status === 200) {
			toast.dismiss();
			toast.success('Успешно')
		}
		return data
	} else {
		const data = await axiosClient.post(`${URL}`, jsonData, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
			.catch(error => {
				httpStatusChecker(error)
			})
		return data
	}
}

export async function PUT(URL, payload, settings = {}) {
	let jsonData = JSON.stringify(payload);
	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosClient.put(
			URL, jsonData,
			{ headers: { Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } }
		)
			.catch(error => {
				return error
			});

		store.dispatch(hideLoader());
		if (data && data.status === 200) {
			toast.dismiss();
			toast.success('Успешно')
		} else {
			httpStatusChecker(data)
		}

		return data?.data
	} else {
		const data = await axiosClient.put(`${URL}`, jsonData, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
		return data?.data
	}

}

export async function FILE(URL, payload, settings = {}) {
	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosFile.post(`${URL}`, payload, { headers: { Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } })
			.catch(error => {
				store.dispatch(hideLoader());
				toast.error('Ошибка' + error)
				return error
			});
		store.dispatch(hideLoader());
		return data.data

	} else {
		const data = await axiosFile.post(`${URL}`, payload, { headers: { "Content-Type": "multipart/form-data", Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } });
		return data.data
	}
}

export async function DELETE(URL, payload, settings = {}) {
	var params = "";
	if (Object.entries(payload).length > 0) {
		params = getPath(payload, URL);
	}

	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosClient.delete(`${URL + params}`, { headers: { Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } })
			.catch(error => {
				httpStatusChecker(error)
				return error
			});
		store.dispatch(hideLoader());
		if (data && data.status === 200) {
			toast.dismiss();
			toast.success('Удалено')
		} else {
			httpStatusChecker(data)
		}
		return data.data
	} else {
		const data = await axiosClient.delete(`${URL}`, { headers: { Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } })
			.catch(error => {
				httpStatusChecker(error)
				return error
			});
		store.dispatch(hideLoader());
		return data.data
	}
}

export async function GUESTGET(URL, payload, settings = {}) {
	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosClient.get(`${URL}`).then(response => response.data);
		store.dispatch(hideLoader());
		return data
	} else return axiosClient.get(`${URL}`).then(response => response.data);
}

export async function GUESTPOST(URL, payload, settings = {}) {
	let jsonData = JSON.stringify(payload);
	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosClient.post(
			`${URL}`,
			jsonData,
			{ headers: { Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } }
		)
			.catch(error => {
				httpStatusCheckerGuess(error)
				return error
			});
		store.dispatch(hideLoader());
		return data.data
	} else {
		return await axiosClient.post(`${URL}`, jsonData).then(response => response.data);
	}
}

function getPath(payload, url) {
	let iterations = Object.entries(payload).length;
	var pathArr = "?";
	if (url)
		url.includes("?") ? pathArr = '&' : pathArr = '?'

	for (let key in payload) {
		if (payload[key]) {
			if (!--iterations) {
				pathArr += key + "=" + payload[key];
			} else {
				pathArr += key + "=" + payload[key] + "&";
			}
		}
	}
	return pathArr;
}

function httpStatusChecker(error) {
	toast.dismiss();

	if (error?.response?.status === 400) {
		toast.error(error.response.data.message)
		return;
	}
	if (error?.response?.status === 401) {
		clearTemporaryStorage()
		if (process.env.NODE_ENV === 'production') {
			window.location.href = "https://cabinet.backend.mison.uz/#/login";
		} else {
			window.location.href = "http://localhost:3007/#/auth/login";
		}

		toast.error('Логин или пароль неправильны')
		return;
	}
	if (error?.response?.status === 404) {
		toast.error('Не найдено')
		return;
	}
	if (error?.response?.status === 415) {
		toast.error('Ошибка')
		return;
	}
	if (error?.response?.status === 500) {
		var message = ""
		if (error.response.data.file) {
			message += error.response.data.file
		}
		if (error.response.data.line) {
			message += error.response.data.line
		}
		if (error.response.data.message) {
			message += error.response.data.message
		}
		toast.error(message)
		return;
	}
	if (!error.status) { // если нету интернета то выходит эта ошибка
		return
	}
}

function httpStatusCheckerGuess(error) {
	if (error?.response?.status === 400) {
		toast.error(error.response.data.message)
	}
	if (error?.response?.status === 401) {
		toast.error('Логин или пароль неправильны')
	}
}
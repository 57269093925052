import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import 'assets/css/rating.css'
import { POST } from 'helpers/api';

function Index() {
	const { pos_id, cheque_order_type_id, transaction_id } = useParams()

	const reduxMenuData = useSelector(state => state.menuData)

	const [showSuccess, setShowSuccess] = useState(false)
	const [data, setData] = useState([])

	async function createRating() {
		var sendData = {
			'pos_id': pos_id,
			'cheque_order_type_id': cheque_order_type_id,
			'transaction_id': transaction_id,
			'answers': [],
		}
		sendData.answers = data
		const response = await POST('/openapi/answers', sendData)
		if (response) setShowSuccess(true)
	}

	function changeRateable(index, index2) {
		const dataCopy = [...data]
		for (let i = 0; i < dataCopy[index].options.length; i++) {
			dataCopy[index].options[i].selected = false
		}
		dataCopy[index].options[index2].selected = !dataCopy[index].options[index2].selected
		setData(dataCopy)
	}

	function changeSelectable(index, index2) {
		const dataCopy = [...data]
		dataCopy[index].options[index2].selected = !dataCopy[index].options[index2].selected
		setData(dataCopy)
	}

	function changeRadio(index, index2) {
		const dataCopy = [...data]
		for (let i = 0; i < dataCopy[index].options.length; i++) {
			dataCopy[index].options[i].selected = false
		}
		dataCopy[index].options[index2].selected = !dataCopy[index].options[index2].selected
		setData(dataCopy)
	}

	function changeCheckbox(index, index2) {
		const dataCopy = [...data]
		dataCopy[index].options[index2].selected = !dataCopy[index].options[index2].selected
		setData(dataCopy)
	}

	function changeTextarea(e, index) {
		const dataCopy = [...data]
		dataCopy[index].value = e.target.value
		setData(dataCopy)
	}

	async function getData() {
		var sendData = {
			'pos_id': pos_id,
			'cheque_order_type_id': cheque_order_type_id,
		}
		
		const response = await POST(`/openapi/questions`, sendData)
		if (response) setData(response.data)
	}

	useEffect(() => {
		getData()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{!showSuccess ?
				<>
					<div className="rating" style={{ color: reduxMenuData?.online_menu_theme?.text_color }}>
						{data.map((item, index) => (
							<div className="rating-block" key={index}>
								<div className="question">
									{index + 1}. {item.name}
								</div>

								<div className="input_types">
									{item.input_type === "rateable" &&
										<div className="rateable">
											{item.options?.map((item2, index2) => (
												<div className="rateable-item " key={index2} onClick={() => changeRateable(index, index2)}
													style={{
														'color': item2.selected ? reduxMenuData?.online_menu_theme?.button_bg_color_plus : 'inherit',
														'borderColor': item2.selected ? reduxMenuData?.online_menu_theme?.button_bg_color_plus : '#757575',
													}}>
													<i className="uil-star"></i>
													<div>{item2.value}</div>
												</div>
											))}
										</div>
									}
									{item.input_type === "selectable" &&
										<div className="selectable">
											<div className="selectable-items">
												{item.options.map((item2, index2) => (
													<div className="selectable-item" key={index2} onClick={() => changeSelectable(index, index2)}
														style={{
															'color': item2.selected ? reduxMenuData?.online_menu_theme?.button_bg_color_plus : 'inherit',
															'borderColor': item2.selected ? reduxMenuData?.online_menu_theme?.button_bg_color_plus : '#757575',
														}}>
														{item2.value}
													</div>
												))}
											</div>
										</div>
									}
									{item.input_type === "radio" &&
										<div className="radio">
											{item.options.map((item2, index2) => (
												<div className="radio-item" key={index2} onClick={() => changeRadio(index, index2)}>
													<div className="custom-radio"
														style={{ 'backgroundColor': item2.selected ? reduxMenuData?.online_menu_theme?.button_bg_color_plus : '#fff' }} />
													<label className="form-check-label" for={item2.value}>
														{item2.value}
													</label>
												</div>
											))}
										</div>
									}
									{item.input_type === "checkbox" &&
										<div className="checkbox">
											{item.options.map((item2, index2) => (
												<div className="checkbox-item" key={index2} onClick={() => changeCheckbox(index, index2)}>
													<div className="custom-checkbox"
														style={{ 'backgroundColor': item2.selected ? reduxMenuData?.online_menu_theme?.button_bg_color_plus : '#fff' }} />
													<label className="form-check-label" for={item2.value}>
														{item2.value}
													</label>
												</div>
											))}
										</div>
									}
									{item.input_type === "textarea" &&
										<div className="textarea">
											<textarea name="value" className="form-control textarea-item" rows={6}
												onChange={(e) => changeTextarea(e, index)} />
										</div>
									}
								</div>
							</div>
						))}
					</div>
					<div className="pos-order focus">
						<button className="justify-content-center"
							onClick={() => createRating()}
							style={{
								'color': reduxMenuData?.online_menu_theme?.button_text_color,
								'backgroundColor': reduxMenuData?.online_menu_theme?.button_bg_color_plus,
							}}>
							Подтвердить
						</button>
					</div>
				</>
				:
				<div className="success-page" style={{ 'backgroundColor': reduxMenuData.online_menu_theme?.body_bg_color }}>
					<div>
						<div>
							<i className="uil uil-check-square"></i>
						</div>
						<div style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
							Спасибо за ваш отзыв
						</div>
						<span style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
							Ваш отзыв был отправлен
						</span>
					</div>
				</div>
			}
		</>
	)
}

export default Index
import React from 'react'

import Menu from '../pages/menu/Menu'
import Products from '../pages/menu/Products'
import Basket from '../pages/menu/Basket'
import Cheque from '../pages/menu/Cheque'

import Feedback from '../pages/feedback/Index'

const menuRoutes = [
	{ path: "/menu/:pos_id", component: <Menu /> },
	{ path: "/menu/products/:menu_id", component: <Products /> },
	{ path: "/menu/basket", component: <Basket /> },
	{ path: "/menu/cheque", component: <Cheque /> },
]

const ratingRoutes = [
	{ path: "/feedback/:pos_id/:cheque_order_type_id?/:transaction_id?", component: <Feedback /> },
]

export { ratingRoutes, menuRoutes }
import React, { useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DebounceInput } from 'react-debounce-input'

import { SET_CART } from 'store/cart/action'
import { formatMoney } from 'helpers/helpers'

function Search() {
	const { menu_id } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const [showSearch, setShowSearch] = useState(false)
	const [searchValue, setSearchValue] = useState('')

	const [cart, setCart] = useState([])
	const [products, setProducts] = useState([])
	const reduxMenuData = useSelector(state => state.menuData)

	function setSearch() {
		setShowSearch(!showSearch)
		if (showSearch) {
			setSearchValue('')
			setTimeout(() => {
				setProducts([])
			}, 300);
		}
	}

	function clearBasket() {
		navigate(`/${reduxMenuData.pos.id}?table_id=${reduxMenuData.table.table_id}`)
		dispatch(SET_CART([]))
	}

	function addProduct(product) {
		var cartCopy = [...cart]
		var index = cartCopy.findIndex(item => item.id === product.id)

		if (index === -1 && index !== undefined) { // if not exist same product in the itemsList
			product.quantity = 1
			cartCopy.push(product)
		} else {
			cartCopy[index]['quantity'] += 1
		}

		setCart(cartCopy)
		dispatch(SET_CART(cartCopy))
	}

	function handleChangeQuantity(product, type) {
		var cartCopy = [...cart]
		var index = cartCopy.findIndex(item => item.id === product.id)

		if (type === 'plus') {
			cartCopy[index]['quantity'] += 1
		}
		if (type === 'minus') {
			if (cartCopy[index]['quantity'] === 1) {
				cartCopy.splice(index, 1)
			} else {
				cartCopy[index]['quantity'] -= 1
			}

		}
		setCart(cartCopy)
		dispatch(SET_CART(cartCopy))
	}

	function getProductQuantity(id) {
		return cart.find(item => item.id === id)?.quantity
	}

	async function searchProducts(value) {
		setSearchValue(value)
		if (value === '') {
			setProducts([])
			return;
		}
		if (value.length > 2) {
			var allProducts = reduxMenuData['products']
			var result = allProducts.filter(element =>
				element.name.toString().toLowerCase().includes(value.toLowerCase())
			)
			setProducts(result)
		}
	}

	function isBack() {
		if (location.pathname.includes('/products') || location.pathname.includes('/basket') || location.pathname.includes('/cheque')) {
			return true
		}
		return false
	}

	function getData() {
		if (location.pathname.includes('/products')) {
			return reduxMenuData?.menu[menu_id]?.name
		}
		if (location.pathname.includes('/basket')) {
			return 'Корзина'
		}
		if (location.pathname.includes('/cheque')) {
			return 'Чек'
		}
		return 'Меню'
	}

	// useEffect(() => {
	// 	getData()
	// }, [location])  // eslint-disable-line react-hooks/exhaustive-deps


	return (
		<>
			<div className="menu-item-search">
				<div className={"search-header " + (showSearch ? 'show' : '')}>
					{isBack() &&
						<div className="back-button" onClick={() => navigate(-1)} style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
							<i className="uil uil-angle-left"></i>
							Назад
						</div>
					}
					<div className="search-header-title" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
						{getData()}
					</div>
					<div className={"menu-item-search-form " + (showSearch ? 'show' : '')}>
						<div className="menu-item-search-form-field">
							<DebounceInput
								type="text"
								className="base-form-input"
								style={{
									color: reduxMenuData.selectedTheme?.input_text_color,
									backgroundColor: reduxMenuData.selectedTheme?.input_bg_color,
									'--placeholder-color': reduxMenuData.selectedTheme?.input_text_color
								}}
								debounceTimeout={300}
								value={searchValue}
								placeholder="Поиск"
								onChange={(e) => searchProducts(e.target.value)}
								onKeyUp={(e) => {
									if (e.keyCode === 13) {
										searchProducts(e.target.value)
									}
								}} />
						</div>
					</div>
					{location.pathname !== '/cheque' &&
						<div className="search-button" onClick={() => setSearch()}>
							{showSearch ?
								<i className="uil uil-times"></i>
								:
								<i className="uil uil-search"></i>
							}
						</div>
					}
					{location.pathname === '/basket' &&
						<div className="search-button" onClick={() => clearBasket()} title="Удалить корзину">
							<i className="uil uil-trash-alt text-danger"></i>
						</div>
					}
				</div>
				{products.length > 0 &&
					<div className={"search-result-list " + (showSearch ? 'opacity-1' : 'opacity-0')}>
						{products.map((item, index) => (
							<div key={index} className="search-item">
								<div className="search-image me-2">
									{item.image ?
										<img src={`https://backend.mison.uz/uploads/products/${item.image}`} alt={item.image} className="product-image v-photoswipe-thumbnail" />
										:
										<img src='https://backend.mison.uz/no_image.png' alt="no-item" className="product-image v-photoswipe-thumbnail" />
									}
								</div>
								<div className="w-100">
									<div className="search-title">
										{item.name}
									</div>
									<div className="row">
										<div className="col-6">
											<div className="search-price">
												{formatMoney(item.sale_price)} сум
											</div>
										</div>
										{reduxMenuData.table &&
											<div className="col-6">
												{getProductQuantity(item.id) ?
													<div className="product-action">
														<button className="product-button minus"
															style={{ backgroundColor: reduxMenuData.online_menu_theme?.button_bg_color_minus, color: reduxMenuData.online_menu_theme?.button_minus_text_color }}
															onClick={(e) => handleChangeQuantity(item, 'minus')}>
															<i className="uil uil-minus"></i>
														</button>
														<span style={{ color: reduxMenuData.online_menu_theme?.text_color }} className="product-count">
															{getProductQuantity(item.id)}
														</span>
														<button className="product-button plus"
															style={{ backgroundColor: reduxMenuData.online_menu_theme?.button_bg_color_plus, color: reduxMenuData.online_menu_theme?.button_text_color }}
															onClick={(e) => handleChangeQuantity(item, 'plus')}>
															<i className="uil uil-plus"></i>
														</button>
													</div>
													:
													<button className="product-button add px-2"
														style={{ backgroundColor: reduxMenuData?.online_menu_theme?.button_bg_color_plus, color: reduxMenuData?.online_menu_theme?.button_text_color }}
														onClick={() => addProduct(item)}>
														Добавить
													</button>
												}
											</div>
										}
									</div>
								</div>
							</div>
						))
						}
					</div>
				}
			</div >
		</>
	)
}

export default Search
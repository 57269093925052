const cart = function (state = [], action) {
	switch (action.type) {
		case "SET_CART":
			return state = action.payload;
		default:
			return state;
		/* falls through */
	}
};

export default cart
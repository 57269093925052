import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { SET_CART, SET_MENU_DATA } from 'store/actions'
import { formatMoney } from 'helpers/helpers'
import { GET } from 'helpers/api'

import Search from './Search'

import 'assets/css/menu.css'

function Layout() {
	const { pos_id } = useParams()
	const location = useLocation()
	const dispatch = useDispatch()

	const reduxCart = useSelector(state => state.cart)
	const reduxMenuData = useSelector(state => state.menuData)

	const [data, setData] = useState(reduxMenuData)
	const [menu, setMenu] = useState([{}, {}, {}, {}, {}, {}, {}, {}])
	const [loading, setLoading] = useState(false)
	const [showStickyHeader, setShowStickyHeader] = useState(false)

	function getTotalPrice(items = []) {
		var totalPrice = 0
		for (let i = 0; i < items.length; i++) {
			totalPrice += items[i]['quantity'] * items[i]['sale_price']
		}
		totalPrice = totalPrice + ((totalPrice * reduxMenuData['table']?.service_percentage) / 100)
		return formatMoney(totalPrice)
	}

	async function getMenu() {
		const table_id = new URLSearchParams(location.search).get('table_id');
		if (reduxMenuData?.pos?.id?.toString() !== pos_id) {
			dispatch(SET_MENU_DATA({}))
			dispatch(SET_CART([]))
		}
		var params = {}
		if (table_id) {
			params.table_id = table_id
		}
		setLoading(true)
		const response = await GET(`/menu/${pos_id}`, params)
		if (response) {
			response.lastLogin = new Date().getTime()
			setData(response)
			setMenu(response?.menu)
			dispatch(SET_MENU_DATA(response))
			setLoading(false)
		}
	}

	function handleScroll() {
		if (window.scrollY >= 190) {
			if (!showStickyHeader) {
				setShowStickyHeader(true)
			}
		} else {
			if (!showStickyHeader) {
				setShowStickyHeader(false)
			}
		}
	}

	function getData() {
		const table_id = new URLSearchParams(location.search).get('table_id');
		var menuLifeTime = reduxMenuData.lastLogin;
		var difference = Math.floor(((Date.now() - menuLifeTime) / 1000) / 60);
		if (menuLifeTime && Number(difference) > 30) {
			dispatch(SET_MENU_DATA({}))
			dispatch(SET_CART([]))
			getMenu()
			return;
		}
		if (reduxMenuData?.pos?.id?.toString() !== pos_id) {
			dispatch(SET_MENU_DATA({}))
			dispatch(SET_CART([]))
			getMenu()
			return;
		}
		if (reduxMenuData?.table?.table_id?.toString() !== table_id) {
			getMenu();
			return;
		}
		if (reduxMenuData?.pos?.id) {
			setData(reduxMenuData)
			setMenu(reduxMenuData?.menu)
			return;
		} else {
			getMenu()
			return;
		}
	}

	useEffect(() => {
		getData()
		window.removeEventListener('scroll', handleScroll);
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => window.removeEventListener('scroll', handleScroll);
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="menu-list" style={{ backgroundColor: reduxMenuData.online_menu_theme?.body_bg_color }}>
				<div className={!showStickyHeader ? "" : "opacity-0"}>
					<Search title="Меню" />
				</div>
				<div className="row">
					{menu?.map((item, index) => (
						<div className="col-6" key={index}>
							<div className="menu-item">
								{!loading ?
									<Link to={`/menu/products/${index}${reduxMenuData.table ? '?table_id=' + reduxMenuData.table.table_id : ''}`} className="menu-item__link">
										{item.image ?
											<img src={`https://backend.mison.uz/uploads/menu/${item.image}`} alt={item.image} />
											:
											<img src='https://backend.mison.uz/no_image.png' alt="" />
										}
										<h2 style={{ color: reduxMenuData.online_menu_theme?.text_color }}>{item.name}</h2>
									</Link>
									:
									<Skeleton height={176} key={index} style={{ borderRadius: '26px' }} />
								}
							</div>
						</div>
					))}
				</div>
			</div>
			{!!(reduxCart?.length && reduxMenuData.table) &&
				<div>
					<Link to="/menu/basket" className="pos-order focus">
						<button style={{ backgroundColor: data?.online_menu_theme?.button_bg_color_plus, color: data?.online_menu_theme?.button_text_color }}>
							<i className="uil uil-arrow-circle-right"></i>
							<div>Посмотреть заказ</div>
							<div>{getTotalPrice(reduxCart)} сум</div>
						</button>
					</Link>
				</div>
			}
		</>
	)
}

export default Layout
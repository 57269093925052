import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GUESTPOST } from 'helpers/api';
import { SET_CART } from 'store/cart/action'
import { formatMoney } from 'helpers/helpers'

import 'assets/css/menu.css'
import 'react-loading-skeleton/dist/skeleton.css'

function LayoutBasket() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const reduxCart = useSelector(state => state.cart)
	const reduxMenuData = useSelector(state => state.menuData)

	const [showSuccess, setShowSuccess] = useState(false)
	const [cart, setCart] = useState([])

	async function createOrder() {
		var sendData = {
			pos_id: reduxMenuData.pos.id,
			hall_id: reduxMenuData.table.hall_id,
			table_id: reduxMenuData.table.table_id,
			service_percentage: reduxMenuData.table.service_percentage,
			products: reduxCart,
		}
		const response = await GUESTPOST('/qr-menu-order', sendData)
		if (response) {
			setShowSuccess(true)
			dispatch(SET_CART([]))
			// toast.success('Заказ создан. Ожидайте')
		}
	}

	function clearBasket() {
		navigate(`/menu/${reduxMenuData.pos.id}?table_id=${reduxMenuData.table.table_id}`)
		dispatch(SET_CART([]))
	}

	function handleChangeQuantity(product, type) {
		var cartCopy = [...cart]
		var index = cartCopy.findIndex(item => item.id === product.id)
		if (type === 'plus') {
			cartCopy[index]['quantity'] += 1
		}
		if (type === 'minus') {
			if (cartCopy[index]['quantity'] === 1) {
				cartCopy.splice(index, 1)
			} else {
				cartCopy[index]['quantity'] -= 1
			}
		}
		setCart(cartCopy)
		dispatch(SET_CART(cartCopy))
	}

	function getProductQuantity(id) {
		return cart.find(item => item.id === id)?.quantity
	}

	function getTotalPrice(items = []) {
		var total_amount_service = 0
		for (let i = 0; i < items.length; i++) {
			items[i]['total_price'] = items[i]['quantity'] * items[i]['sale_price']

			if (items[i]['bool_service_percentage']) {
				items[i]['service_percentage'] = reduxMenuData['table']?.service_percentage
				total_amount_service +=
					items[i]['total_price'] +
					(items[i]['total_price'] * reduxMenuData['table']?.service_percentage / 100)
			} else {
				items[i]['service_percentage'] = 0
				total_amount_service += items[i]['total_price']
			}
		}
		return formatMoney(total_amount_service)
	}

	useEffect(() => {
		if (!reduxMenuData.table) {
			navigate(-1)
		}
		if (reduxCart.length) {
			setCart(reduxCart)
		}
		window.scrollTo(0, 0)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{!showSuccess ?
				<>
					<div className="menu-list" style={{ backgroundColor: reduxMenuData.online_menu_theme?.body_bg_color }}>
						<div className={"search-header"}>
							<div className="back-button" onClick={() => navigate(-1)} style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
								<i className="uil uil-angle-left"></i>
								Назад
							</div>
							<div className="search-header-title" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
								<span>Корзина</span>
							</div>
							<div className="search-button" onClick={() => clearBasket()} title="Удалить корзину">
								<i className="uil uil-trash-alt text-danger"></i>
							</div>
						</div>
						<div className="menu-item-list">
							{reduxCart?.map((item, index) => (
								<div className="mb-30" key={index}>
									<div className="d-flex">
										<div className="col-3 me-3">
											<div className="base-lazy__loader"></div>
											{item.image ?
												<img src={`https://backend.mison.uz/uploads/products/${item.image}`} alt={item.image} className="product-image v-photoswipe-thumbnail" />
												:
												<img src='https://backend.mison.uz/no_image.png' alt="no-item" className="product-image v-photoswipe-thumbnail" />
											}
										</div>
										<div className="w-100 d-flex justify-content-around flex-column">
											<div>
												<div className="product-title" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
													<span>{item.name}</span>
												</div>
											</div>
											<div className="d-between">
												<div className="product-price mb-0 col-6">
													<span className="product-price__current" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
														<b>{formatMoney(item.sale_price)}</b>
														<span>сум</span>
													</span>
												</div>
												<div className="product-action col-6">
													<button className="product-button minus"
														style={{ backgroundColor: reduxMenuData.online_menu_theme?.button_bg_color_minus, color: reduxMenuData.online_menu_theme?.button_minus_text_color }}
														onClick={() => handleChangeQuantity(item, 'minus')}>
														<i className="uil uil-minus"></i>
													</button>
													<span className="product-count" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>{getProductQuantity(item.id)}</span>
													<button className="product-button plus"
														style={{ backgroundColor: reduxMenuData.online_menu_theme?.button_bg_color_plus, color: reduxMenuData.online_menu_theme?.button_text_color }}
														onClick={() => handleChangeQuantity(item, 'plus')}>
														<i className="uil uil-plus"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							))
							}
						</div>
					</div>
					{(reduxCart.length > 0) &&
						<div>
							<div className="pos-order second focus">
								<button onClick={() => createOrder()}
									style={{ backgroundColor: reduxMenuData?.online_menu_theme?.button_bg_color_plus, color: reduxMenuData?.online_menu_theme?.button_text_color }}>
									<div>Оформить заказ</div>
									<div className="pos-order-total">
										<div className="pos-order-total-price">{getTotalPrice(reduxCart)} сум</div>
									</div>
								</button>
							</div>
						</div>
					}
				</>
				:
				<div className="success-page" style={{ backgroundColor: reduxMenuData.online_menu_theme?.body_bg_color }}>
					<div>
						<div style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
							Ваш заказ успешно оформлен
						</div>
						<span style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
							Обратитесь к официанту для подтверждения заказа
						</span>
						<div>
							<i className="uil uil-check-square"></i>
						</div>
					</div>
					<div>
						<div className="pos-order focus">
							<button onClick={() => navigate(`/menu/${reduxMenuData.pos.id}?table_id=${reduxMenuData.table.table_id}`)}
								style={{ backgroundColor: reduxMenuData?.online_menu_theme?.button_bg_color_plus, color: reduxMenuData?.online_menu_theme?.button_text_color }}>
								<div className="text-center w-100">
									Подтвердить
								</div>
							</button>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default LayoutBasket
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import 'react-loading-skeleton/dist/skeleton.css'

import { SET_CART } from 'store/cart/action'
import { formatMoney } from 'helpers/helpers'

import Search from './Search'

import 'assets/css/menu.css'

function Layout() {
	const { menu_id } = useParams()
	const dispatch = useDispatch()

	const reduxCart = useSelector(state => state.cart)
	const reduxMenuData = useSelector(state => state.menuData)

	const [products, setProducts] = useState([])
	const [cart, setCart] = useState([])
	const [showStickyHeader, setShowStickyHeader] = useState(false)

	function addProduct(product) {
		var cartCopy = [...cart]
		var index = cartCopy.findIndex(item => item.id === product.id)

		if (index === -1 && index !== undefined) { // if not exist same product in the itemsList
			product.quantity = 1
			cartCopy.push(product)
		} else {
			cartCopy[index]['quantity'] += 1
		}

		setCart(cartCopy)
		dispatch(SET_CART(cartCopy))
	}

	function handleChangeQuantity(product, type) {
		var cartCopy = [...cart]
		var index = cartCopy.findIndex(item => item.id === product.id)

		if (type === 'plus') {
			cartCopy[index]['quantity'] += 1
		}
		if (type === 'minus') {
			if (cartCopy[index]['quantity'] === 1) {
				cartCopy.splice(index, 1)
			} else {
				cartCopy[index]['quantity'] -= 1
			}

		}
		setCart(cartCopy)
		dispatch(SET_CART(cartCopy))
	}

	function getProductQuantity(id) {
		return cart.find(item => item.id === id)?.quantity
	}

	function getTotalPrice(items = []) {
		var totalPrice = 0
		for (let i = 0; i < items.length; i++) {
			totalPrice += items[i]['quantity'] * items[i]['sale_price']
		}
		totalPrice = totalPrice + ((totalPrice * reduxMenuData['table'].service_percentage) / 100)
		return formatMoney(totalPrice)
	}

	function handleScroll() {
		if (window.scrollY >= 190) {
			if (!showStickyHeader) {
				setShowStickyHeader(true)
			}
		} else {
			if (!showStickyHeader) {
				setShowStickyHeader(false)
			}
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		window.removeEventListener('scroll', handleScroll);
		window.addEventListener('scroll', handleScroll, { passive: true });
		setProducts(reduxMenuData?.menu[menu_id]?.products)
		if (reduxCart.length) {
			setCart(reduxCart)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="menu-list" style={{ backgroundColor: reduxMenuData.online_menu_theme?.body_bg_color }}>
				<div className={!showStickyHeader ? "opacity-1" : "opacity-0"}>
					<Search />
				</div>
				{(menu_id) &&
					<div className="menu-item-list">
						{/* <div className="menu-item-list__title mb-3 h2">
								<span>{menu[menu_id]?.name}</span>
							</div> */}
						<div className="row">
							{products?.map((item, index) => (
								<div className="col-6 mb-30" key={index}>
									<div className="menu-item-list__item">
										<div className="base-lazy product-image _loaded">
											<div className="base-lazy__loader"></div>
											{item.image ?
												<img src={`https://backend.mison.uz/uploads/products/${item.image}`} alt={item.image} className="product-image v-photoswipe-thumbnail" />
												:
												<img src='https://backend.mison.uz/no_image.png' alt="no-item" className="product-image v-photoswipe-thumbnail" />
											}
										</div>
										<div className="product-content">
											<div className="product-body">
												<div className="product-header">
													<div className="product-title" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
														{item.name}
													</div>
												</div>
											</div>
										</div>
										<div className="product-footer">
											<div className="product-price">
												<span className="product-price__current" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>
													<b>{formatMoney(item.sale_price)}</b>
													<span>сум</span>
												</span>
											</div>
											{reduxMenuData.table ? getProductQuantity(item.id) ?
												<div className="product-action">
													<button className="product-button minus"
														style={{ backgroundColor: reduxMenuData.online_menu_theme?.button_bg_color_minus, color: reduxMenuData.online_menu_theme?.button_minus_text_color }}
														onClick={(e) => handleChangeQuantity(item, 'minus')}>
														<i className="uil uil-minus"></i>
													</button>
													<span className="product-count" style={{ color: reduxMenuData.online_menu_theme?.text_color }}>{getProductQuantity(item.id)}</span>
													<button className="product-button plus"
														style={{ backgroundColor: reduxMenuData.online_menu_theme?.button_bg_color_plus, color: reduxMenuData.online_menu_theme?.button_text_color }}
														onClick={(e) => handleChangeQuantity(item, 'plus')}>
														<i className="uil uil-plus"></i>
													</button>
												</div>
												:
												<button className="product-button add"
													style={{ backgroundColor: reduxMenuData?.online_menu_theme?.button_bg_color_plus, color: reduxMenuData?.online_menu_theme?.button_text_color }}
													onClick={() => addProduct(item)}>
													Добавить
												</button>
												:
												<div></div>
											}
										</div>
									</div>
								</div>
							))}
						</div>

					</div>
				}
			</div>
			{!!(reduxCart.length && reduxMenuData.table) &&
				<div>
					<Link to={'/menu/basket'} className="pos-order focus">
						<button style={{ backgroundColor: reduxMenuData?.online_menu_theme?.button_bg_color_plus, color: reduxMenuData?.online_menu_theme?.button_text_color }}>
							<i className="uil uil-arrow-circle-right"></i>
							<div>Посмотреть заказ</div>
							<div>{getTotalPrice(reduxCart)} Сум</div>
						</button>
					</Link>
				</div>
			}
		</>
	)
}

export default Layout